const CXR_PA_DB = `CXR  (PA)  (23/9/2023)   

The heart is of normal size and shape.
The pulmonary vessels are of normal size with normal distribution.
There is no abnormality noted in both lung fields and in the ribs.
The mediastinum is of normal appearances.

CONCLUSION

Normal chest x-ray appearances.  
`

export default CXR_PA_DB;
