const ULTRASOUND_ABDOMEN_DW = `ULTRASOUND ABDOMEN

Liver shows a normal echogenicity.  It is of normal size.  There is no focal liver lesion.
Gallbladder is of normal appearances and there is no gallstone seen.
Biliary tree is of normal calibre.  
Both kidneys are of normal echotexture and no evidence of calculus, mass or hydronephrosis.
Pancreas is of normal size and echogenicity.
Spleen is of normal size with no focal lesion noted within.
There is no evidence of abdominal aortic aneurysm.

CONCLUSION

Normal ultrasound abdomen.
       
`

export default ULTRASOUND_ABDOMEN_DW;
