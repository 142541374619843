const CXR_PA_DP = `CXR (PA) 15/9/2023

The lung field is clear.
Normal heart size and shape.
Normal mediastinum and bilateral hila.
Bilateral costophrenic angles are clear.
Imaged bones are unremarkable. 

CONCLUSION

Normal study.
`

export default CXR_PA_DP;
