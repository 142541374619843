const ULTRASOUND_BOTH_BREAST_DW = `ULTRASOUND BOTH  BREASTS (23/9/2023)

BI-RADS ULTRASOUND ASSESSMENT CATEGORIES
0 : Incomplete:  additional imaging evaluation needed
1 : Negative
2 : Benign
3 : Probably benign:  short-interval follow up recommended
4 : Suspicious:  biopsy
5 : Highly suggestive of malignancy:  biopsy
6 : Known malignancy:  treatment ongoing

`

export default ULTRASOUND_BOTH_BREAST_DW;
