const ULTRASOUND_NECK_DW = `ULTRASOUND NECK (23/9/2023)

Categories
TIRADS 1 : Normal exam
TIRADS 2 : Benign
TIRADS 3 : Probably Benign 
TIRADS 4 : Suspicious
TIRADS 5 : Suggestive of malignancy
TIRADS 6 : FNAB-confirmed malignancy

`

export default ULTRASOUND_NECK_DW;
