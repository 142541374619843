const ULTRASOUND_ABDOMEN_DH = `ULTRASOUND ABDOMEN (23/9/2023)

Liver shows a normal echogenicity.  It is of normal size.  There is no focal liver lesion.
Gallbladder is of normal appearances and there is no gallstone seen.  Gallbladder wall is not thickened.  
Common duct is of normal calibre measuring     mm.
Right and left kidney measures   cm and   cm in length respectively.  Both kidneys are of normal echotexture, with no stone, mass or hydronephrosis.
Pancreas is of normal size and echogenicity.
Spleen is of normal size measuring    cm in length with no focal lesion noted within.  
Normal abdominal aorta.  
No ascites or para aortic lymph nodes.

CONCLUSION
Normal ultrasound abdomen.

`

export default ULTRASOUND_ABDOMEN_DH;
