const CXR_CARDIOMEGALY_DH = `CXR (PA) (23/9/2023)

The heart is enlarged with CT ratio of 
The lung fields are clear.
No widening of mediastinum.
Trachea is central.
No bony lesion.

CONCLUSION

Cardiomegaly.
`

export default CXR_CARDIOMEGALY_DH;
