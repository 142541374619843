const BILATERAL_MAMMOGRAM_DW = `BILATERAL MAMMOGRAM (23/9/2023)

No previous film for comparison.  Comparison with previous mammogram films will enhance accuracy and sensitive for breast cancer detection.
Birads breasts density category B.
Normal mammogram appearances.

CONCLUSION

Both breasts - Category 1.

Comments about mammogram:
• Approximately 10% of Breast Cancer is not detectable mammographically. A negative mammogram report should not delay biopsy if there is a clinically suspicious finding.
• Dense parenchyma can obscured a neoplasm and reduce mammographic sensitivity.
• When a mammogram finding is characterized as probably benign, it is based on statistical probability. Occasionally, a cancer can mimic benign process. Biopsy is the most definite diagnostic procedure.
Category 0 - Require additional assessment, 
Category 1 - Negative for malignancy,    
Category 2 - Benign
Category 3 - Probably benign, short interval follow up  
Category 4 - suspicious abnormality, biopsy should be considered.   
Category 5 - Hightly suspicious of malignancy.
`

export default BILATERAL_MAMMOGRAM_DW;
