const ULTRASOUND_ABDOMEN_DB = `ULTRASOUND ABDOMEN (23/9/2023)

Liver shows a normal echogenicity.  It is of normal size.  There is no focal liver lesion.
Gallbladder is of normal appearances and there is no gallstone seen.
Common duct is of normal calibre.  
Biliary tree is of normal calibre.  
Right and left kidneys measure    and   cm in length respectively.  Both kidneys are of normal echotexture, with no stone, mass or hydronephrosis.
Pancreas is of normal size and echogenicity.
Spleen is of normal size with no focal lesion noted within.
Normal size aorta.

CONCLUSION

Normal ultrasound abdomen.
`

export default ULTRASOUND_ABDOMEN_DB;
